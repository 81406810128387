@font-face {
  font-family: "Brother 1816";
  src: local("Brother 1816 Light"), local("Brother-1816-Light"),
    url("./fonts/Brother1816-Light.woff2") format("woff2"),
    url("./fonts/Brother1816-Light.woff") format("woff"),
    url("./fonts/Brother1816-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Brother 1816";
  src: local("Brother 1816 Light Italic"), local("Brother-1816-Light-Italic"),
    url("./fonts/Brother1816-LightItalic.woff2") format("woff2"),
    url("./fonts/Brother1816-LightItalic.woff") format("woff"),
    url("./fonts/Brother1816-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Brother 1816";
  src: local("Brother 1816 Bold"), local("Brother-1816-Bold"),
    url("./fonts/Brother1816-Bold.woff2") format("woff2"),
    url("./fonts/Brother1816-Bold.woff") format("woff"),
    url("./fonts/Brother1816-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Brother 1816", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
