.main {
  background-color: #000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px 50px;
  border-top: 2px solid #d3a131;
}
.partners {
  display: flex;
  align-items: center;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  width: 60%;
}
.partnersImage {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.title {
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  margin-left: 30px;
}
.icons {
  font-size: 40px;
  display: flex;
  align-items: center;
}
.icons i {
  margin-left: 50px;
  color: #fff;
}
.icons img {
  width: 100px;
  margin-left: 50px;
}

@media screen and (max-width: 1270px) {
  .main {
    flex-direction: column;
  }
  .icons {
    margin-top: 50px;
  }
  .partners {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .partners {
    flex-direction: column;
  }
  .title {
    margin-left: 0;
    margin-right: 20px;
    margin-bottom: 40px;
  }
  .partnersImage {
    justify-content: space-between;
  }
  .partnersImage img {
    height: 50px;
  }
  .partners {
    border-right: none;
    border-left: none;
  }
  .icons i {
    margin-right: 20px;
    margin-left: 0;
  }
  .icons img {
    width: 70px;
    margin-left: 0;
  }
}
