.App {
  position: relative;
  background: url("./assets/bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  padding: 0 10%;
}

.App::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.85
  ); /* change the opacity value to adjust darkness */
  z-index: -1;
}

.activeLink {
  color: #d3a131 !important;
}

@media screen and (max-width: 992px) {
  .App {
    padding: 0 3%;
  }
}
