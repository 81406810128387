.btn {
  color: #fff;
  text-decoration: none;
  border: 2px solid #d3a131;
  text-align: center;
  padding: 11px 40px;
  border-radius: 50px;
  font-weight: 300;
  display: inline-block;
  font-size: 24px;
  height: 27px;
  width: 140px;
  transition: all 0.4s ease;
  cursor: pointer;
}

.btn:hover {
  background: #d3a131;
}
