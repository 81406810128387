.navBar {
  background-color: #242525;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: sticky;
  top: 0;
  z-index: 3000;
  height: 100px;
}

.navBarMenu {
  display: flex;
  transition: all 0.5s ease;
}

.navBarMenu li {
  color: #fff;
  margin-left: 50px;
  display: flex;
  align-self: center;
  cursor: pointer;
}

.navBarMenu li a {
  color: #fff;
  text-decoration: none;
  font-size: 24px;
}

.icon {
  color: white;
  font-size: 2.5rem;
  cursor: pointer;
  display: none;
}

.logo {
  width: 100px;
}

@media (max-width: 960px) {
  .navBar {
    height: 80px;
    justify-content: left;
  }
  .logo {
    width: 90px;
    margin-left: 30px;
  }
  .icon {
    display: block;
    position: fixed;
    top: 20px;
    right: 30px;
  }
  .navBarMenu {
    display: none;
  }
  .navBarMenu li {
    margin-left: 0px;
  }
  .navBarMenuActive {
    display: block;
    position: absolute;
    text-align: center;
    top: 64px;
    background-color: #242525;
    width: 100%;
    padding: 20px 0 20px 0;
    animation: slide-in 0.5s ease-out forwards;
  }

  .navBarMenuActive li a {
    display: block;
    font-size: 1.563rem;
    color: #fff;
    text-decoration: none;
    margin: 0 auto;
    font-size: 24px;
  }
  .navBarMenuActive li {
    padding: 20px 0;
    color: #fff;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
