.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 90px;
}
.golden {
  color: #d3a131;
  font-weight: bold;
}
.quote {
  font-style: italic;
  font-size: 24px;
  quotes: "„";
  line-height: 40px;
}
.speakers {
  list-style: none;
  padding: 0;
}
.titles {
  margin-bottom: 50px;
}
.title {
  font-size: 47px;
  font-weight: normal;
  margin: 0 0 10px 0;
}
.titlebold {
  font-size: 47px;
  font-weight: bold;
}
.content {
  width: 55%;
}
.btnWrap {
  margin: 70px 0;
  display: flex;
  justify-content: flex-end;
}
.images {
  width: 45%;
  display: flex;
  justify-content: center;
}
.image {
  width: 500px;
}

@media screen and (max-width: 1270px) {
  .image {
    width: 400px;
  }
}

@media screen and (max-width: 992px) {
  .main {
    display: flex;
    flex-direction: column;
  }
  .content {
    width: 100%;
  }
  .btnWrap {
    justify-content: center;
  }
  .speakers {
    text-align: center;
  }
  .titlebold {
    text-align: center;
    margin: 20px 40px 50px 0;
  }
  .images {
    width: 100%;
    display: flex;
  }
  .image {
    max-width: 300px;
    margin: 0 auto;
  }
}
