.speaker {
  font-size: 28px;
  margin-bottom: 10px;
}

.openBtn {
  font-size: 20px;
  color: #d3a131;
  cursor: pointer;
}

.closeBtn {
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.active {
  font-weight: bold;
  color: #d3a131;
}

.expandable {
  min-height: 0;
}
.descWrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 700ms;
  font-size: 20px;
  color: #fff;
  max-width: 600px;
  font-weight: normal;
  line-height: 30px;
  margin: 10px 0;
}

.descWrapperActive {
  grid-template-rows: 1fr;
}
.citiesWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.city {
  text-align: center;
  width: 100px;
  background-color: #d3a131;
  border-radius: 20px;
  font-size: 16px;
  margin: 10px 10px 0 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 992px) {
  .showText {
    margin: 20px auto;
  }
  .descWrapper {
    margin: 10px auto;
  }
  .citiesWrapper {
    justify-content: center;
  }
}
