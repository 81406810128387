.main {
  text-align: center;
  padding: 150px 0;
  animation: fadeIn 3s ease;
}
.quotes {
  list-style: none;
  max-width: 1100px;
  margin: 0 auto;
  padding-left: 0;
  transition: all 0.5s ease;
}
.title {
  font-size: 45px;
  font-weight: normal;
  margin-bottom: 60px;
}
.btnOpen {
  border: none;
  background-color: transparent;
  color: #d3a131;
  cursor: pointer;
  font-size: 24px;
  margin: 20px 0 25px;
  letter-spacing: 2px;
}
.quote {
  font-size: 28px;
  font-weight: 300;
  margin: 20px 0;
}
.quoteLast {
  font-size: 28px;
  font-weight: 700;
}
.quoteGold {
  font-size: 36px;
  color: #d3a131;
  margin: 40px 0;
}
.golden {
  color: #d3a131;
  font-weight: bold;
}
.quoteGoldListHidden {
  display: none;
}

.quoteGoldListOpen {
  display: block;
  animation: fadeIn 4s ease;
  margin-bottom: 55px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 992px) {
  .main {
    padding: 50px 0 100px;
  }
}
