.main {
  width: 50%;
  margin-bottom: 80px;
}
.title {
  font-size: 28px;
  font-weight: 300;
}

@media screen and (max-width: 992px) {
  .main {
    width: 100%;
  }
}
