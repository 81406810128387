.main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
}
.block {
  width: 45%;
}
.title {
  font-size: 47px;
  margin-top: 0;
}
.image {
  width: 500px;
  text-align: center;
  display: block;
  margin: 0 auto;
}
.text {
  font-size: 22px;
  margin-bottom: 100px;
  line-height: 50px;
}

@media screen and (max-width: 1270px) {
  .image {
    width: 400px;
  }
}

@media screen and (max-width: 992px) {
  .main {
    flex-direction: column;
  }
  .block {
    width: 100%;
    text-align: center;
  }
  .image {
    width: 300px;
    margin: 0 auto;
    margin-top: 40px;
  }
  .text {
    margin-bottom: 35px;
    margin-top: 70px;
  }
}
