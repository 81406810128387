.main {
  display: flex;
  margin-bottom: 100px;
}
.title {
  font-size: 47px;
  margin: 0 0 10px 0;
}
.text {
  font-size: 28px;
  margin-top: 60px;
  margin-bottom: 80px;
  line-height: 40px;
}
.content {
  width: 60%;
}
.golden {
  font-weight: bold;
  color: #d3a131;
}
.imageWraper {
  margin: 0 auto;
}

@media screen and (max-width: 992px) {
  .main {
    flex-wrap: wrap;
  }
  .content {
    width: 100%;
  }
  .title {
    text-align: center;
  }
  .text {
    text-align: center;
  }
  .icon {
    height: 200px;
  }
}
