.main {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 100px;
}

@media screen and (max-width: 992px) {
  .main {
    flex-direction: column;
    align-content: center;
  }
}
