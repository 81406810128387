.main {
  display: flex;
  align-items: flex-end;
  padding-bottom: 100px;
}
.block {
  width: 50%;
}
.numbers {
  list-style: none;
  text-align: right;
  font-size: 24px;
}
.numbers li {
  margin-bottom: 30px;
  line-height: 50px;
}
.title {
  font-size: 47px;
}
.icons {
  font-size: 50px;
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.icons i {
  color: #fff;
}
.text {
  font-size: 24px;
  line-height: 50px;
}
.subtitle {
  font-size: 28px;
}
.websiteLink {
  text-decoration: underline;
  color: #fff;
}

@media screen and (max-width: 992px) {
  .main {
    flex-direction: column;
  }
  .block {
    width: 100%;
  }
  .numbers {
    list-style: none;
    font-size: 24px;
    padding-left: 0;
    text-align: center;
  }
  .text {
    text-align: center;
  }
  .title {
    text-align: center;
  }
  .icons {
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .subtitle {
    text-align: center;
  }
}
